<template>
  <ion-page>
    <ion-content>
      <div class="nk-wrap">
        <main-block class="p-0">
          <div class="p-3 mx-n2 border-bottom bg-white d-flex justify-between">
            <div class="d-flex align-center">
              <div class="user-avatar">
                <span>{{analytics.lead.initials}}</span>
              </div>
              <h5 class="fw-light ml-3">Lead #{{analytics.lead.lead_number}}</h5>
            </div>
            <nk-button type="primary" v-on:click="$router.go(-1)">Close</nk-button>
          </div>
          <row>
            <column default="12" :md="6" :lg="3" class="bg-white border-right py-3" :class="{'min-vh-100': isPlatform('desktop')}">
              <h6>{{$t('Visitor Details')}}</h6>
              <div class="mt-3 mb-4 rounded border border-2 bg-lighter p-3">
                <b>Acquired:</b>
              </div>
              <div class="d-flex align-center mb-1" v-for="(lb,index) in visitorDetailsLabels" :key="index">
                <b class="mr-1 text-capitalize">{{$t(lb.label)}}:</b>
                <span>{{ analytics.visitor[lb.obj_prop_name ? lb.obj_prop_name : lb.label.toLowerCase()] }}</span>
              </div>
              <hr class="border-top bg-transparent"/>
            </column>
            <column default="12" :md="6" :lg="3" :xl="2" class="py-3 px-lg-4" id="sessions_wrapper" :class="{'desktop' : isPlatform('desktop')}">
              <h6>{{$t('Sessions')}}</h6>
              <div class="text-center pt-5" v-if="loadings.visitor_data">
                <ion-spinner></ion-spinner>
              </div>
              <div v-else-if="errors.analytics_error">
                <b class="text-muted">{{ errors.analytics_error }}</b>
              </div>
              <template v-else>
                <div class="rounded mb-3 session_box" v-for="(session, index) in analytics.sessions_result"
                     :class="session.is_active ? 'bg-primary-dim':'bg-white'"
                     :key="'ss'+index"
                     v-on:click="getSessionDetails(session.session_id)">
                  <div class="fw-bold text-center px-3 py-1 border-bottom">{{ session.date }}</div>
                  <div class="py-2 px-3">
                    <div>
                      <nio-icon icon="ni-eye text-primary"></nio-icon> {{session.pageviews}} {{$t('Page Views')}}
                    </div>
                    <div>{{session.duration}} {{$t('Duration')}}</div>
                  </div>
                </div>
              </template>
            </column>
            <column default="12" :lg="6" :xl="7" class="py-3 border-left px-lg-4" id="session_details_wrapper"
                    :class="{'mobile': isPlatform('mobile'), 'd-none': isPlatform('mobile') && !openSessionDetails}">
              <div class="mx-n3 pb-1 px-3 border-bottom mb-2" v-if="isPlatform('mobile')">
                <nk-button type="primary" v-on:click="openSessionDetails=false">{{ $t('Close') }}</nk-button>
              </div>
              <h6>{{$t('Session Details')}}</h6>
              <div v-if="loadings.session_details" class="text-center pt-5">
                <ion-spinner></ion-spinner>
              </div>
              <div v-else-if="errors.session_events_error">
                <b class="text-muted">{{errors.session_events_error}}</b>
              </div>
              <div v-else-if="!sessionDetails.session.date">
                <b class="text-muted">{{$t('pages.lead_analytics.select_a_session')}}</b>
              </div>
              <template v-else>
                <div class="bg-white border rounded">
                  <row>
                    <column default="12" :md="6" :lg="12" :xl="6" class="border-right py-1 pr-md-0 pr-lg-2 pr-xl-0 nk-text-dark">
                      <div class="px-3">
                        <div>
                          <span style="font-size: 1rem" class="mr-2 fw-500">Start:</span> <span>{{ sessionDetails.session.date}}</span>
                        </div>
                        <div class="d-flex mt-1">
                          <nio-icon icon="ni-signin" style="font-size: 1.4rem"></nio-icon>
                          <div>
                            <div style="font-size: 1rem;" class="lh-n ml-2 fw-500 nk-text-dark">Landing Page</div>
                            <u class="">{{ sessionDetails.session.start.page}}</u>
                          </div>
                        </div>
                      </div>
                      <div class="border-top d-flex align-center px-3 py-1 mt-1">
                        <nio-icon icon="ni-link" class="mr-2" style="font-size: 1.3rem"></nio-icon>
                        <span style="font-size: 1rem" class="mr-2 fw-500">Source</span>
                        <u class="lh-n" v-if="sessionDetails.session.start.source">{{ sessionDetails.session.start.source}}</u>
                        <span v-else>No source</span>
                      </div>
                    </column>
                    <column default="12" class="d-block d-md-none d-lg-block d-xl-none">
                      <hr class="border-top m-0"/>
                    </column>
                    <column default="12" :md="6" :lg="12" :xl="6" class="py-1 pl-md-0 pl-lg-2 pl-xl-0 nk-text-dark">
                      <div class="px-3">
                        <div>
                          <span style="font-size: 1rem" class="mr-2 fw-500">End:</span> <span>{{sessionDetails.session.end.date }}</span>
                        </div>
                        <div class="d-flex mt-1">
                          <nio-icon icon="ni-signout" style="font-size: 1.4rem"></nio-icon>
                          <div>
                            <div style="font-size: 1rem;" class="lh-n ml-2 fw-500 nk-text-dark">Exit Page</div>
                            <u class="">{{ sessionDetails.session.end.page}}</u>
                          </div>
                        </div>
                      </div>
                      <div class="border-top d-flex align-center px-3 py-1 mt-1">
                        <nio-icon icon="ni-clock" class="mr-2" style="font-size: 1.3rem"></nio-icon>
                        <span style="font-size: 1rem" class="mr-2 fw-500">Duration</span>
                        <span class="lh-n"><!--5 min. and 23 sec.--> <!-- TODO --> {{sessionDetails.session.end.duration}}</span>
                      </div>
                    </column>
                  </row>
                </div>
                <h6 class="my-4">{{$t('Session Events')}}</h6>
                <accordion id="session_events">
                  <accordion-item v-for="(sEvent,index) in sessionDetails.events" :key="'ev'+index" class="light-shadow">
                    <accordion-item-head :data-target="'#event-'+index" :collapsed="index > 0">
                      <div class="d-flex align-center">
                        <nio-icon :icon="osIcons[analytics.visitor.os_name.toLowerCase()] || ''" style="font-size: 1.1rem"></nio-icon>
                        <nio-icon :icon="analytics.visitor.device_type==='desktop' ? 'ni-laptop':'ni-mobile'" class="mr-2" style="font-size: 1.1rem"></nio-icon>
                        <span class="text-dot-dot">{{ sEvent.event_uuid }}</span>
                      </div>
                    </accordion-item-head>
                    <accordion-item-body :id="'event-'+index" data-parent="#session_events" :collapsed="index > 0" class="border-top"
                                         :no-padding="isPlatform('mobile')">
                      <div class="mb-3 mt-2 mt-lg-0">
                        <div class="d-block d-md-flex align-items-center lh-n">
                          <nio-icon icon="ni-signin" class="mr-1 nk-text-muted align-middle" v-if="sEvent.type === 'landing_page'" style="font-size: 1.3rem"></nio-icon>
                          <nio-icon icon="ni-eye" class="mr-1 nk-text-muted align-middle" v-else-if="sEvent.type === 'pageview'" style="font-size: 1.3rem"></nio-icon>
                          <span v-if="sEvent.type === 'landing_page'">{{$t('Landing Page')}}</span>
                          <span v-else-if="sEvent.type === 'pageview'">{{$t('Page View')}}</span>
                          <span class="text-primary ml-1 d-block mt-2 mt-md-0">{{sEvent.title}}</span>
                        </div>
                        <div class="text-muted pl-1">{{sEvent.path === '/' ? sessionDetails.session.start.page : sEvent.path}}</div>
                      </div>
                      <div v-for="(cEvent, cIndex) in sEvent.child_events" :key="'c_ev'+cIndex" class="bg-lighter rounded py-1 px-3 mb-1">
                        <div class="d-flex align-center justify-between">
                          <div v-if="cEvent.type === 'scroll'" class="d-block d-md-flex align-items-center">
                            <nio-icon icon="ni-exchange-v" class="mr-2 fw-bold nk-text-dark"></nio-icon>
                            <span class="text-dark">{{$t('Scrolled')}} <span class="text-blue ml-0 ml-md-2 text-.85rem d-block d-md-inline">{{cEvent.data.scroll.percentage}}% {{$t('scrolled_from_top')}}</span></span>
                          </div>
                          <div v-else-if="cEvent.type === 'click'">
                            <button class="btn btn-light btn-sm mr-1" v-on:click="cEvent.show_details=!cEvent.show_details">
                              <b class="mr-1">{{$t('Click')}}:</b> <span class="fw-light">{{ $t('Show') }} {{cEvent.show_details ? $t('Less'):$t('More') }}</span>
                            </button>
                          </div>
                          <div v-else-if="cEvent.type === 'resize'">
                            <nio-icon icon="ni-maximize" class="mr-2 fw-bold nk-text-dark"></nio-icon>
                            <span class="text-dark">{{$t('Resize')}} <span class="text-blue ml-0 ml-md-2 text-.85rem d-block d-md-inline">{{$t('to')}} {{cEvent.data.viewport.width+'x'+cEvent.data.viewport.height}} {{$t('viewport')}}.</span> </span>
                          </div>
                          <span class="text-muted">{{cEvent.date.split(' ')[1]}}</span>
                        </div>
                        <div v-if="cEvent.type === 'click' && cEvent.show_details" style="font-size: .8rem;" class="py-1 px-3 mt-2 border rounded mb-3">
                          <div class="text-.85rem fw-bold text-nk-dark mb-1">{{$t('Action Details')}}</div>
                          <div class="d-block d-md-flex">
                            <span class="nk-text-dark mr-2 d-block" :class="isPlatform('mobile') ? 'fw-bold':'fw-500'">{{$t('Date performed')}}:</span>
                            <span class="nk-text-muted">{{cEvent.date_formatted}}</span>
                          </div>
                          <div class="d-block d-md-flex" v-if="cEvent.last_action_time_diff">
                            <span class="fw-500 nk-text-dark mr-2 d-block" :class="isPlatform('mobile') ? 'fw-bold':'fw-500'">{{$t('Time from last action')}}:</span>
                            <span class="nk-text-muted">{{cEvent.last_action_time_diff}}</span>
                          </div>
                          <div class="d-block d-md-flex">
                            <span class="fw-500 nk-text-dark mr-2 d-block" :class="isPlatform('mobile') ? 'fw-bold':'fw-500'">{{$t('Element')}}:</span>
                            <span class="nk-text-muted">{{cEvent.data.element}}</span>
                          </div>
                          <div class="d-block d-md-flex">
                            <span class="fw-500 nk-text-dark mr-2 d-block" :class="isPlatform('mobile') ? 'fw-bold':'fw-500'">{{$t('Text')}}:</span>
                            <span class="nk-text-muted">{{cEvent.data?.text}}</span>
                          </div>
                        </div>
                      </div>
                    </accordion-item-body>
                  </accordion-item>
                </accordion>
              </template>
            </column>
          </row>
        </main-block>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {IonContent, IonPage, isPlatform, IonSpinner} from "@ionic/vue";
import {Column, MainBlock, Row} from "@core/layouts";
import {onMounted, reactive, ref, watch} from "vue";
import NioIcon from "@core/components/nio-icon/NioIcon";
import Accordion from "@core/components/accordion/Accordion";
import AccordionItem from "@core/components/accordion/components/AccordionItem";
import AccordionItemHead from "@core/components/accordion/components/AccordionItemHead";
import AccordionItemBody from "@core/components/accordion/components/AccordionItemBody";
import axios from "@/libs/axios";
import {useRoute} from "vue-router";
import useCommonFunc from "@core/comp-functions/common"
import NkButton from "@core/components/button/NkButton";

export default {
  name: "Analytics",
  components:{
    NkButton,
    AccordionItemBody,
    AccordionItemHead, AccordionItem, Accordion, NioIcon, Column, Row, MainBlock, IonContent, IonPage, IonSpinner},
  setup(){

    const {updateObject} = useCommonFunc()
    const route = useRoute()

    let errors = reactive({
      analytics_error: '',
      session_events_error: ''
    })
    let loadings = reactive({
      visitor_data: false,
      session_details: false,
    })
    let analytics = reactive({
      lead:{
        initials: '',
        lead_number: '',
      },
      sessions_result:[],
      visitor:{
        browser_name: '',
        browser_version: '',
        date: '',
        device_type: '',
        first_session: '',
        initial_page: '',
        initial_source: '',
        last_date: '',
        location: '',
        os: ''
      }
    })
    let sessionDetails = reactive({
      session: {
        date: '',
        end:{
          date: '',
          duration: '',
          page: '',
        },
        start:{
          date: '',
          page: '',
          source: '',
        }
      },
      events: [],
    })
    const visitorDetailsLabels = [
      {
        label: 'Location',
      },
      {
        label: 'Device',
        obj_prop_name: 'device_type'
      },
      {
        label: 'OS',
      },
      {
        label: 'Browser',
        obj_prop_name: 'browser_name'
      },
      {
        label: 'Initial Source',
        obj_prop_name: 'initial_source',
      },
      {
        label: 'Initial Page',
        obj_prop_name: 'initial_page',
      },
      {
        label: 'First Session',
        obj_prop_name: 'date',
      },
      {
        label: 'Last seen',
        obj_prop_name: 'last_date',
      }
    ]
    const osIcons = {
      windows: 'ni-windows',
      ios: 'ni-apple',
      macos: 'ni-apple',
    }
    let openSessionDetails = ref(false)

    const getVisitorData = ()=>{
      if(!route.params.id){
        return
      }
      loadings.visitor_data = true
      errors.analytics_error = ''
      axios.post('/api/admin/analytics/visitor_data', {lead_id: route.params.id})
      .then(r=>{
        if(r.data.success){
          updateObject(analytics, r.data.data)
        }else{
          errors.analytics_error = r.data.message
        }
      })
      .catch().then(()=> loadings.visitor_data = false)
    }
    const getSessionDetails = (id)=>{
      if(isPlatform('mobile')){
        openSessionDetails.value = true
      }
      loadings.session_details = true
      for(let x in analytics.sessions_result){
        analytics.sessions_result[x].is_active = analytics.sessions_result[x].session_id === id
      }
      errors.session_events_error = ''
      axios.post('/api/admin/analytics/session_events', {session_id: id})
          .then(r=>{
            if(r.data.success){
              updateObject(sessionDetails, r.data.data)
            }else{
              errors.session_events_error = r.data.message
            }
          })
          .catch().then(()=> loadings.session_details = false)
    }
    watch(()=>route.params.id, ()=> getVisitorData())
    onMounted(()=> getVisitorData())

    return{
      analytics,
      errors,
      getSessionDetails,
      isPlatform,
      loadings,
      openSessionDetails,
      osIcons,
      sessionDetails,
      visitorDetailsLabels,
    }
  }
}
</script>

<style scoped>
.session_box{
  cursor: pointer;
}
#sessions_wrapper.desktop{
  max-height: 90vh;
  overflow: hidden;
  overflow-y: auto;
}
#sessions_wrapper.desktop::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

#sessions_wrapper.desktop::-webkit-scrollbar
{
  width: 7px;
  background-color: #F5F5F5;
}

#sessions_wrapper.desktop::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #a4a4a4;
}
#session_details_wrapper.mobile{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 99;
  overflow-y: auto;
}
.text-dot-dot{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>